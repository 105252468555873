<template lang="html">
  <li class='tab' v-show='isActive'>
    <font-awesome-icon
      class="label-icon"
      :icon="icon"
    ></font-awesome-icon> <span class="ml-2 tab-title">{{ title }}</span>
  </li>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Tab'
    },
    icon: {
      type: String,
      default: 'video'
    }
  },
  data () {
    return {
      isActive: true
    }
  }
}
</script>
<style lang="scss" scoped>
  li {
    padding: 9px 20px;
    cursor: pointer;
  }
</style>